.component_info {
	display: none;
	background-color: black;
	padding-left: 4px;
	padding-right: 4px;
}

img:not([alt]) {border: 4px red dashed !important;}

//$body-bg: #000;
$theme-colors: ("primary": #ac9259);

$body-bg: #eaeaea;

$container-max-widths: (
md: 720px,
lg: 960px,
xl: 1400px
) !default;

.gold {
	color: map-get($theme-colors, "primary");
}

.unsichtbar {
	display: none;
}

.sidebar-list-item {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	background-color: white;
	border-bottom: 1px solid map-get($theme-colors, "primary");
}

.sidebar-list-item a {
		color: black;
}

.sidebar-list-item:hover {
	background-color: #eee;
	border-bottom: 1px solid map-get($theme-colors, "primary");
	color: map-get($theme-colors, "primary");
}

.sidebar-list-item:not(:first-child) > a {
	text-decoration: none;
	flex-grow: 4;
}

.sidebar-list-item:not(:first-child) > a:hover {
	color: map-get($theme-colors, "primary");
}

.sidebar-list-item:first-child > a {
	color: map-get($theme-colors, "primary");
	text-decoration: none;
}

.sidebar-list-item > a > div:first-child {
	padding: 0.5rem 1rem;
}

.sidebar-list-item-button {
	border-left: 1px solid #ddd;
	width: 2.5rem;
	text-align: center;
	color: #aaa;
}

.sidebar-list-item-button svg {
	margin-top: 10px;
}

.sidebar-list > div:first-child {
	background-color: #343a40;
	color: map-get($theme-colors, "primary");
	border-bottom: 1px solid map-get($theme-colors, "primary");
}

.sidebar-list-item-active {
	background-color: #343a40;
}

.sidebar-list-item-active a {
	color: map-get($theme-colors, "primary");
}

.sidebar-list-item-active:hover {
	//background-color: black;
	background-color: #343a40;
	color: map-get($theme-colors, "primary");
}

.sidebar-list-level-3 {
	padding-left: 2rem;
}

.sidebar-list-level-3 > .sidebar-list-item-button {
	display: none; // hide level 4 –> 2 css eintraege, hier und eins weiter oben
}

.sidebar-list-level-4 {
	display: none; // hide level 4 –> 2 css eintraege, hier und eins weiter oben
	//padding-left: 3rem;
}



// GroupCard
@keyframes example {
	0% {
		max-height: 0;
		opacity: 0;
	}
	50% {
		max-height: 200px;
	}
	100% {
		opacity: 1;
	}
}

.prices-product > tbody > tr {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	margin: 0px;
	background-color: red;
}

.groupcard-bottom-info {
	//animation-name: example;
	//animation-duration: 0.5s;
}

.mute {
	font-size: 12px;
	color: grey;
}

.grey {
	color: grey;
}

.table {
	margin-bottom: 0 !important;
}

.navbar-dark {
	margin-bottom: 30px;
}

// Set-Card

.set-card-title > h1 {
	font-size: 18px;
	margin-bottom: 0px;
	font-weight: 600;
}

.set-card-title > h1 > .year {
	font-size: 22px;
	color: map-get($theme-colors, "primary");
	font-weight: 600;
}

.set-card-title > p {
	margin-bottom: 8px;
}

.set-card-container {
	background-color: white;
	margin-bottom: 35px;
	padding: 10px;
}

.set-card-container-flex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 12px;
	text-align: center;
}

.group-container {
	background-color: black;
	margin-bottom: 35px;
	padding: 10px;
}

.group-container-flex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	min-height: 300px;
	align-items: center;
	gap: 12px;
	text-align: center;
}

.set-card-product > p {
	margin-bottom: 0;
}

.set-card-picture {
	max-width: 160px;
	max-height: 160px;
}

.set-card-picture-great {
	max-width: 260px;
	max-height: 260px;
}

.group-picture {
	max-width: 140px;
	max-height: 140px;
}

.th-price {
	width: 100px;
	min-width: 100px;
}

.main {
	background-color: white;
	padding: 10px 30px 5px 30px;
	margin: 30px 0px 30px 0px;
}

.main > h1 {
	margin-top: 20px;
	margin-bottom: 30px;
	font-size: 1.2em;
	font-weight: 800;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: map-get($theme-colors, "primary");
}

.main > h2 {
	font-size: 1em;
	font-weight: 600;
	margin-top: 30px;
	letter-spacing: 1px;
	color: map-get($theme-colors, "primary");
}

.main > h3 {
	font-size: 1em;
	font-weight: 600;
	margin-top: 30px;
}

.main > h4 {
	font-size: 0.9em;
	font-weight: 600;
	margin-top: 10px;
}

.pictogram {
	width: 30px;
}

.th-price > .pictogram {
	width: 28px;
}

.main {
	font-size: 0.9em;
	margin-bottom: 30px;
}

.zero-line {
	margin: 0;	
	padding: 0;	
	margin-top: 5px;
	margin-bottom: 5px;
}



// Sidebar

#navigation-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	//transition: all 1s linear;
	//max-width: 100%;
}

#navigation-list.transition {
	//opacity: 0;
	//max-width: 0;
}

#navigation-list > a > li {
	background-color: #343a40;
	color: map-get($theme-colors, "primary");
	padding: 0.5rem 1rem;
	border-bottom: 1px solid map-get($theme-colors, "primary");
}

#navigation-list > a:hover {
	text-decoration: none !important;
}

#navigation-list > ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

#navigation-list > ul > li {
	padding: 0.5rem 1rem;
	color: #aaa;
	cursor: not-allowed;
	background-color: white;
	border-bottom: 1px solid map-get($theme-colors, "primary");
}

#navigation-list > ul > a > li {
	padding: 0.5rem 1rem;
	background-color: white;
	border-bottom: 1px solid map-get($theme-colors, "primary");
}

#navigation-list > ul > a > li:hover {
	background-color: #eee;
	border-bottom: 1px solid map-get($theme-colors, "primary");
}

#navigation-list > ul > a {
	color: #343a40;
}

#navigation-list > ul > a:hover {
	color: #343a40;
	text-decoration: none;
}

#navigation-list > ul > a > .active {
	background-color: #343a40;
}

#navigation-list > ul > a > .active:hover {
	background-color: #343a40;
}

#navigation-list > ul > a > .active {
	color: map-get($theme-colors, "primary") !important;
}

#navigation-list > ul > a:hover {
	color: map-get($theme-colors, "primary");
}

// Sidebar END

.headerbox {
	border-bottom: 1px solid map-get($theme-colors, "primary");
	height: 105px;
	//height: 135px;
	background-color: #343a40;
}

.headerlogo {
	width: 70px;
	//width: 100px;
	padding-top: 25px;
	cursor: pointer;
}

// Auktion

.card {
	margin-top: 30px;
}

.card:hover {
	box-shadow: 0px 0px 5px grey;
}

.card-header {
	color: map-get($theme-colors, "primary");
	background-color: #343a40 !important;
	font-size: 0.8em;
	padding: 5px 10px 5px 10px !important;
}

.card-body {
	padding: 5px 10px 20px 10px !important;
	font-size: 0.8em;
}

.card-title {
	font-size: 1.2em !important;
	line-height: 1.3em !important;
	letter-spacing: 0.5px;
	font-weight: 700 !important;
	color: map-get($theme-colors, "dark");
}


.miniatur-bild {
	height: 150px;
	margin: 10px;
	//background-color: black;
	//border-radius: 10px;
}

.miniatur-bild img {
	max-height: 100%;
	max-width: 100%;
}

// List-View
.bild_liste_body {
	margin-top: 15px;
	margin-right: 25px;
}

.bild_liste_rahmen {
	height: 100%;      /* equals max image height */
	width: 100%;
	background-color: black;
	white-space: nowrap;
	text-align: center;
	border-radius: 0.25rem 0rem 0rem 0.25rem;
	padding: 5px;
}

.bild_liste_helper {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.bild_liste {
	background: #3A6F9A;
	vertical-align: middle;
	max-height: 100%; /* equals Frame height */
	max-width: 100%;
}



.card-footer {
	font-size: 0.8em;
}

.detail > h1 {
	color: map-get($theme-colors, "primary");
	font-size: 1.3em;
}

.detail > h2 {
	font-size: 1.1em;
}

.detail > p {
	margin-bottom: 0;
}

.detail > h3 {
	font-size: 1em;
	text-transform: uppercase;
}

.detail > .image {
	background-color: black;
	text-align: center;
}

.detail > .image > img {
	max-height: 350px !important;
}

.image-gallery-image {
	max-height: 350px !important;
	background-color: black;
}

.image-gallery-thumbnails-wrapper {
	display: none;
}

.fullscreen > .image-gallery-thumbnails-wrapper {
	display: inherit;
}

.fullscreen > div > div > div > div > div > .image-gallery-image {
	max-height: calc(100vh - 130px) !important;
}

.fullscreen > div > div > div > div > .image-gallery-image {
	max-height: calc(100vh) !important;
}


.produktbeschrieb {
	margin-top: 20px;
}

.detail-container {
	padding-top: 30px;
	padding-bottom: 10px;
}

.detail > ul > li {
	margin-left: -25px;
	font-size: 0.9em;
}

.versteigerungsbedingungen > li {
	margin-bottom: 15px;
}

.btn {
	letter-spacing: 1px;
}


// Shop ShoppingCartOverview

.shoppingCartButton {
	margin-top: 30px;
}

// Auction

.auktionTitel {
	margin-top: 30px;
	margin-bottom: 0px;
	font-size: 1.2em;
	font-weight: 800;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: map-get($theme-colors, "dark");
}

.suchbegriff {
	color: grey;
	font-size: 20px;
	text-align: right;
	margin-left: 15px;
}

// Pagination

.pagination {
	margin-top: 20px;
}

// ProductMiniaturCart

.productRow {
	background-color: white;
	margin: 15px;
	margin-left: 0px !important;
	margin-right: 0px !important;
	border: 1px solid #ddd;
	border-radius: 10px;
	padding: 20px;
}

.productRowImg {
	max-height: 110px;
}


#page-container {
	position: relative;
	min-height: 100vh;
}

#content-wrap {
	padding-bottom: 10.0rem;
	/* Footer height */
}

#footer {
	position: absolute;
	bottom: 0;
	width: 100%;

	background-color: #343a40;
	margin-top: 40px;
	padding-top: 30px;
	padding-bottom: 30px;
	line-height: 2em;
}

.suchbegriffInfo {
	margin-top: 15px;
}

.shoppingCartElement > div {
	padding: 5px;
}

.center-column {
	display: flex;
	flex-direction: column;
	align-items:center;
}

.center-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	column-gap: 20px;
}

#main {
	position: relative;
	margin: 0px;
	margin-top: 20px;
	padding-top: 45px;
	padding-bottom: 40px;
	display: -webkit-flex;
	display:         flex;
	-webkit-flex-flow: row;
	flex-flow: row;
	align-items:center;
	background-color: white;
}

#main > article {
	-webkit-flex: 3 1 60%;
	flex: 3 1 60%;
	-webkit-order: 2;
	order: 2;
}

#main > #left {
	margin: 4px;
	margin-right: 15px;
	cursor: pointer;
	padding: 5px;
	color: map-get($theme-colors, "primary");
	-webkit-order: 1;
	order: 1;
}

#main > #right {
	margin: 4px;
	margin-left: 15px;
	padding: 5px;
	cursor: pointer;
	color: map-get($theme-colors, "primary");
	-webkit-order: 3;
	order: 3;
}

.borderless-table {
	border: 0px;
}

.table th, .table td {
	border-top: 0px !important;
}

.prices-table {
}

.prices-table th, .prices-table td {
	padding: 0 0.5rem 0 0 !important;
}

.prices-table th {
	max-width: 70px;
	width: 70px;
}

.prices-table td {
	max-width: 130px;
	width: 130px;
}


.table-row-price {
	max-width: 120px;
	width: 120px;
}


.backcross {
	font-size: 28px;
	z-index: 5;
	position: absolute;
	top: 0px;
	right: 8px;
	opacity: 0.4;
	transition: 0.3s;
	cursor: pointer;
	font-style: normal;
	font-family: Arial, monospace;
}

.backcross:hover {
	opacity: 1.0;
}

#searchbar-dropdown {
	width: 88px;
}

#searchbar-category {
	width: 187px;
}


// Navigation

//.nav-link {
//font-size: 0.8em;
//text-transform: uppercase;
//letter-spacing: 1.5px;
//}



// Import MDB-Icons Stuff
@import 'material-icons/iconfont/material-icons.scss';

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

// Import Image Gallery Stuff
@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";
